import React from "react";

function PrivacyPolicyPl({site_name, site_host, admin_email}) {
    return (
        <div><h1>Polityka prywatności</h1><p>Ostatnia aktualizacja: 13 marca 2022</p><p>Niniejsza Polityka prywatności
            opisuje nasze zasady i procedury dotyczące gromadzenia, wykorzystywania i ujawniania Twoich informacji
            podczas korzystania z Usługi oraz informuje o Twoich prawach do prywatności oraz o tym, jak prawo Cię
            chroni.</p><p>Wykorzystujemy Twoje dane osobowe w celu świadczenia i ulepszania Usługi. Korzystając z
            Usługi, zgadzasz się na zbieranie i wykorzystywanie informacji zgodnie z niniejszą Polityką prywatności.</p>
            <h1>Interpretacja i definicje</h1><h2>Interpretacja</h2><p>Słowa, których pierwsza litera pisana jest z
                wielkiej litery, mają znaczenie zdefiniowane w następujących warunkach. Poniższe definicje mają to samo
                znaczenie niezależnie od tego, czy występują w liczbie pojedynczej czy mnogiej.</p><h2>Definicje</h2>
            <p>Na potrzeby niniejszej Polityki prywatności:</p>
            <ul>
                <li><p><strong>Konto</strong> oznacza unikalne konto utworzone dla Ciebie w celu uzyskania dostępu do
                    naszej Usługi lub części naszej Usługi.</p></li>
                <li><p><strong>Firma</strong> (określana w niniejszej Umowie jako „Firma”, „My”, „Nas” lub „Nasz” w
                    niniejszej Umowie) odnosi się do {site_name} .</p></li>
                <li><p><strong>Pliki cookie</strong> to małe pliki, które są umieszczane na Twoim komputerze, urządzeniu
                    mobilnym lub innym urządzeniu przez witrynę internetową, zawierające szczegóły Twojej historii
                    przeglądania tej witryny wśród wielu jej zastosowań.</p></li>
                <li><p><strong>Kraj</strong> odnosi się do: Hiszpania</p></li>
                <li><p><strong>Urządzenie</strong> oznacza każde urządzenie, które ma dostęp do Usługi, takie jak
                    komputer, telefon komórkowy lub tablet cyfrowy.</p></li>
                <li><p><strong>Dane osobowe</strong> to wszelkie informacje dotyczące zidentyfikowanej lub możliwej do
                    zidentyfikowania osoby.</p></li>
                <li><p><strong>Usługa</strong> dotyczy Serwisu.</p></li>
                <li><p><strong>Usługodawca</strong> oznacza każdą osobę fizyczną lub prawną, która przetwarza dane w
                    imieniu Spółki. Odnosi się do firm zewnętrznych lub osób zatrudnionych przez Spółkę w celu
                    ułatwienia świadczenia Usługi, świadczenia Usługi w imieniu Spółki, świadczenia usług związanych z
                    Usługą lub pomocy Spółce w analizie sposobu korzystania z Usługi.</p></li>
                <li><p><strong>Dane dotyczące użytkowania</strong> odnoszą się do danych zbieranych automatycznie,
                    generowanych podczas korzystania z Usługi lub z samej infrastruktury Usługi (na przykład czas
                    trwania wizyty na stronie).</p></li>
                <li><p><strong>Strona internetowa</strong> odnosi się do {site_name} , dostępnej pod adresem
                    {site_host}</p></li>
                <li><p><strong>Użytkownik</strong> oznacza osobę uzyskującą dostęp do Usługi lub korzystającą z niej,
                    firmę lub inny podmiot prawny, w imieniu którego taka osoba uzyskuje dostęp do Usługi lub korzysta z
                    niej, stosownie do przypadku.</p></li>
            </ul>
            <h1>Zbieranie i wykorzystywanie Twoich danych osobowych</h1><h2>Rodzaje gromadzonych danych</h2><h3>Dane
                osobiste</h3><p>Podczas korzystania z naszej usługi możemy poprosić Cię o podanie nam pewnych danych
                osobowych, które mogą być użyte do skontaktowania się z Tobą lub zidentyfikowania Ciebie. Informacje
                umożliwiające identyfikację osoby mogą obejmować między innymi:</p>
            <ul>
                <li>Dane użytkowania</li>
            </ul>
            <h3>Dane użytkowania</h3><p>Dane o użytkowaniu są zbierane automatycznie podczas korzystania z Usługi.</p>
            <p>Dane dotyczące użytkowania mogą obejmować takie informacje, jak adres protokołu internetowego urządzenia
                (np. adres IP), typ przeglądarki, wersja przeglądarki, strony naszego Serwisu, które odwiedzasz, godzina
                i data wizyty, czas spędzony na tych stronach, unikalne urządzenie identyfikatory i inne dane
                diagnostyczne.</p><p>Gdy uzyskujesz dostęp do Usługi za pomocą urządzenia mobilnego lub za jego
                pośrednictwem, możemy automatycznie zbierać pewne informacje, w tym między innymi typ używanego
                urządzenia mobilnego, unikalny identyfikator urządzenia mobilnego, adres IP urządzenia mobilnego, system
                operacyjny, rodzaj mobilnej przeglądarki internetowej, z której korzystasz, unikalne identyfikatory
                urządzeń i inne dane diagnostyczne.</p><p>Możemy również zbierać informacje, które Twoja przeglądarka
                wysyła za każdym razem, gdy odwiedzasz naszą Usługę lub uzyskujesz dostęp do Usługi za pomocą urządzenia
                mobilnego lub za jego pośrednictwem.</p><h3>Technologie śledzenia i pliki cookie</h3><p>Używamy plików
                cookie i podobnych technologii śledzenia, aby śledzić aktywność w naszej usłudze i przechowywać
                określone informacje. Stosowane technologie śledzenia to sygnały nawigacyjne, znaczniki i skrypty do
                zbierania i śledzenia informacji oraz do ulepszania i analizowania naszych usług. Stosowane przez nas
                technologie mogą obejmować:</p>
            <ul>
                <li><strong>Pliki cookie lub pliki cookie przeglądarki. </strong>Cookie to mały plik umieszczany na
                    Twoim urządzeniu. Możesz poinstruować swoją przeglądarkę, aby odrzucała wszystkie pliki cookie lub
                    wskazywała, kiedy plik cookie jest wysyłany. Jeśli jednak nie akceptujesz plików cookie, możesz nie
                    być w stanie korzystać z niektórych części naszego Serwisu. O ile nie dostosowałeś ustawień swojej
                    przeglądarki tak, aby odrzucała pliki cookie, nasz Serwis może wykorzystywać pliki cookie.
                </li>
                <li><strong>Flashowe pliki cookie. </strong>Niektóre funkcje naszego Serwisu mogą wykorzystywać lokalnie
                    przechowywane obiekty (lub pliki cookie Flash) do zbierania i przechowywania informacji o Twoich
                    preferencjach lub Twojej aktywności w naszym Serwisie. Pliki cookie Flash nie są zarządzane przez te
                    same ustawienia przeglądarki, które są używane w przypadku plików cookie przeglądarki. Aby uzyskać
                    więcej informacji na temat usuwania plików cookie Flash, przeczytaj „Gdzie mogę zmienić ustawienia
                    wyłączania lub usuwania lokalnych obiektów udostępnionych?” dostępne pod adresem
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li><strong>Sygnały nawigacyjne w sieci Web. </strong>Niektóre sekcje naszej Usługi i nasze wiadomości
                    e-mail mogą zawierać małe pliki elektroniczne znane jako sygnalizatory sieci Web (określane również
                    jako przezroczyste gify, znaczniki pikselowe i jednopikselowe gify), które pozwalają Firmie, na
                    przykład, zliczać użytkowników, którzy odwiedzili te strony lub otworzył wiadomość e-mail i inne
                    powiązane statystyki witryny (na przykład rejestrowanie popularności określonej sekcji oraz
                    weryfikacja integralności systemu i serwera).
                </li>
            </ul>
            <p>Pliki cookie mogą być „trwałymi” lub „sesyjnymi” plikami cookie. Trwałe pliki cookie pozostają na Twoim
                komputerze osobistym lub urządzeniu mobilnym, gdy przechodzisz w tryb offline, podczas gdy sesyjne pliki
                cookie są usuwane, gdy tylko zamkniesz przeglądarkę internetową.</p><p>Używamy zarówno sesyjnych, jak i
                trwałych plików cookie w celach określonych poniżej:</p>
            <ul>
                <li><p><strong>Niezbędne/niezbędne pliki cookie</strong></p><p>Typ: sesyjne pliki cookie</p>
                    <p>Administrowany przez: Nam</p><p>Cel: Te pliki cookie są niezbędne do świadczenia usług dostępnych
                        za pośrednictwem Witryny i umożliwienia korzystania z niektórych jej funkcji. Pomagają
                        uwierzytelniać użytkowników i zapobiegać nieuczciwemu korzystaniu z kont użytkowników. Bez tych
                        plików cookie usługi, o które prosiłeś, nie mogą być świadczone, a my używamy tych plików cookie
                        wyłącznie w celu świadczenia tych usług.</p></li>
                <li><p><strong>Polityka plików cookie / Powiadomienie o akceptacji plików cookie</strong></p><p>Typ:
                    trwałe pliki cookie</p><p>Administrowany przez: Nam</p><p>Cel: Te pliki cookie identyfikują, czy
                    użytkownicy zaakceptowali korzystanie z plików cookie w Witrynie.</p></li>
                <li><p><strong>Funkcjonalne pliki cookie</strong></p><p>Typ: trwałe pliki cookie</p><p>Administrowany
                    przez: Nam</p><p>Cel: Te pliki cookie pozwalają nam zapamiętać wybory dokonywane podczas korzystania
                    z Witryny, takie jak zapamiętanie danych logowania lub preferencji językowych. Celem tych plików
                    cookie jest zapewnienie bardziej osobistego doświadczenia i uniknięcie konieczności ponownego
                    wprowadzania swoich preferencji za każdym razem, gdy korzystasz z Witryny.</p></li>
            </ul>
            <p>Aby uzyskać więcej informacji o używanych przez nas plikach cookie i Twoich wyborach dotyczących plików
                cookie, odwiedź naszą Politykę plików cookie lub sekcję Pliki cookie w naszej Polityce prywatności.</p>
            <h2>Wykorzystanie Twoich danych osobowych</h2><p>Spółka może wykorzystywać Dane Osobowe w następujących
                celach:</p>
            <ul>
                <li><p><strong>Aby zapewnić i utrzymać naszą Usługę</strong> , w tym monitorować korzystanie z naszej
                    Usługi.</p></li>
                <li><p><strong>W celu zarządzania Twoim Kontem:</strong> w celu zarządzania Twoją rejestracją jako
                    użytkownika Usługi. Podane przez Ciebie Dane Osobowe mogą zapewnić Ci dostęp do różnych funkcji
                    Usługi, z których możesz korzystać jako zarejestrowany użytkownik.</p></li>
                <li><p><strong>W celu wykonania umowy:</strong> opracowanie, zgodność i zawarcie umowy zakupu produktów,
                    przedmiotów lub usług, które nabyłeś lub jakiejkolwiek innej umowy z nami za pośrednictwem Usługi.
                </p></li>
                <li><p><strong>Aby skontaktować się z Tobą:</strong> Aby skontaktować się z Tobą za pośrednictwem poczty
                    elektronicznej, połączeń telefonicznych, SMS-ów lub innych równoważnych form komunikacji
                    elektronicznej, takich jak powiadomienia push aplikacji mobilnej dotyczące aktualizacji lub
                    komunikacji informacyjnej związanej z funkcjonalnościami, produktami lub zakontraktowanymi usługami,
                    w tym aktualizacjami zabezpieczeń, gdy jest to konieczne lub uzasadnione dla ich realizacji.</p>
                </li>
                <li><p><strong>Aby dostarczać Ci</strong> wiadomości, oferty specjalne i ogólne informacje o innych
                    oferowanych przez nas towarach, usługach i wydarzeniach, które są podobne do tych, które już kupiłeś
                    lub o które pytałeś, chyba że zdecydowałeś się nie otrzymywać takich informacji.</p></li>
                <li><p><strong>Aby zarządzać Twoimi prośbami:</strong> Aby uczestniczyć i zarządzać Twoimi prośbami do
                    nas.</p></li>
                <li><p><strong>W przypadku przeniesienia działalności:</strong> możemy wykorzystać Twoje dane do oceny
                    lub przeprowadzenia fuzji, zbycia, restrukturyzacji, reorganizacji, rozwiązania lub innej sprzedaży
                    lub przeniesienia niektórych lub wszystkich naszych aktywów, zarówno w ramach kontynuacji
                    działalności, jak i w ramach upadłości, likwidacji, lub podobne postępowanie, w którym posiadane
                    przez Nas Dane Osobowe dotyczące użytkowników naszego Serwisu znajdują się wśród przekazywanych
                    aktywów.</p></li>
                <li><p><strong>Do innych celów</strong> : Możemy wykorzystywać Twoje dane do innych celów, takich jak
                    analiza danych, identyfikacja trendów użytkowania, określanie skuteczności naszych kampanii
                    promocyjnych oraz ocena i ulepszanie naszej Usługi, produktów, usług, marketingu i Twoich
                    doświadczeń.</p></li>
            </ul>
            <p>Możemy udostępniać Twoje dane osobowe w następujących sytuacjach:</p>
            <ul>
                <li><strong>Usługodawcom:</strong> możemy udostępniać Twoje dane osobowe Usługodawcom w celu
                    monitorowania i analizowania korzystania z naszej Usługi, aby się z Tobą skontaktować.
                </li>
                <li><strong>W przypadku przeniesienia działalności:</strong> Możemy udostępniać lub przekazywać Twoje
                    dane osobowe w związku z lub w trakcie negocjacji dotyczących fuzji, sprzedaży aktywów Spółki,
                    finansowania lub przejęcia całości lub części naszej działalności innej firmie.
                </li>
                <li><strong>Z podmiotami stowarzyszonymi:</strong> możemy udostępniać Twoje dane naszym podmiotom
                    stowarzyszonym, w takim przypadku będziemy wymagać od tych podmiotów stowarzyszonych przestrzegania
                    niniejszej Polityki prywatności. Podmioty stowarzyszone obejmują naszą spółkę macierzystą i wszelkie
                    inne podmioty zależne, partnerów joint venture lub inne firmy, które kontrolujemy lub które znajdują
                    się pod wspólną z nami kontrolą.
                </li>
                <li><strong>Partnerom biznesowym:</strong> Możemy udostępniać Twoje dane naszym partnerom biznesowym,
                    aby oferować Ci określone produkty, usługi lub promocje.
                </li>
                <li><strong>Z innymi użytkownikami:</strong> kiedy udostępniasz dane osobowe lub w inny sposób wchodzisz
                    w interakcję w obszarach publicznych z innymi użytkownikami, takie informacje mogą być przeglądane
                    przez wszystkich użytkowników i mogą być publicznie rozpowszechniane na zewnątrz.
                </li>
                <li><strong>Za Twoją zgodą</strong> : możemy ujawnić Twoje dane osobowe w dowolnym innym celu za Twoją
                    zgodą.
                </li>
            </ul>
            <h2>Przechowywanie Twoich danych osobowych</h2><p>Firma będzie przechowywać Twoje Dane Osobowe tylko tak
                długo, jak jest to konieczne do celów określonych w niniejszej Polityce prywatności. Będziemy
                przechowywać i wykorzystywać Twoje dane osobowe w zakresie niezbędnym do wypełnienia naszych zobowiązań
                prawnych (na przykład, jeśli jesteśmy zobowiązani do zachowania Twoich danych w celu zachowania
                zgodności z obowiązującymi przepisami), rozstrzygania sporów i egzekwowania naszych umów prawnych i
                zasad.</p><p>Firma zachowa również Dane użytkowania do celów analizy wewnętrznej. Dane dotyczące
                użytkowania są zazwyczaj przechowywane przez krótszy okres czasu, z wyjątkiem sytuacji, gdy dane te są
                wykorzystywane do wzmocnienia bezpieczeństwa lub poprawy funkcjonalności Naszej Usługi lub jesteśmy
                prawnie zobowiązani do przechowywania tych danych przez dłuższy czas.</p><h2>Przekazywanie Twoich danych
                osobowych</h2><p>Twoje informacje, w tym Dane Osobowe, są przetwarzane w biurach operacyjnych Spółki
                oraz w innych miejscach, w których znajdują się strony zaangażowane w przetwarzanie. Oznacza to, że te
                informacje mogą być przesyłane do — i przechowywane na — komputerach znajdujących się poza Państwa
                stanem, prowincją, krajem lub inną jurysdykcją rządową, gdzie przepisy dotyczące ochrony danych mogą
                różnić się od tych obowiązujących w Państwa jurysdykcji.</p><p>Twoja zgoda na niniejszą Politykę
                prywatności, a następnie przesłanie przez Ciebie takich informacji oznacza Twoją zgodę na to
                przeniesienie.</p><p>Firma podejmie wszelkie uzasadnione kroki, aby zapewnić, że Twoje dane są
                traktowane w sposób bezpieczny i zgodnie z niniejszą Polityką prywatności, a żadne przekazywanie Twoich
                danych osobowych nie będzie miało miejsca do organizacji lub kraju, chyba że zostaną wprowadzone
                odpowiednie kontrole, w tym bezpieczeństwo Twoje dane i inne dane osobowe.</p><h2>Ujawnianie Twoich
                danych osobowych</h2><h3>Transakcje biznesowe</h3><p>Jeśli Firma jest zaangażowana w fuzję, przejęcie
                lub sprzedaż aktywów, Twoje Dane Osobowe mogą zostać przekazane. Powiadomimy Cię, zanim Twoje dane
                osobowe zostaną przekazane i staną się przedmiotem innej Polityki prywatności.</p><h3>Egzekwowanie
                prawa</h3><p>W pewnych okolicznościach Firma może być zobowiązana do ujawnienia Twoich Danych Osobowych,
                jeśli jest to wymagane przez prawo lub w odpowiedzi na ważne żądania władz publicznych (np. sądu lub
                agencji rządowej).</p><h3>Inne wymagania prawne</h3><p>Firma może ujawnić Twoje Dane Osobowe w dobrej
                wierze, że takie działanie jest konieczne, aby:</p>
            <ul>
                <li>Wypełnij obowiązek prawny</li>
                <li>Chronić i bronić praw lub własności Firmy</li>
                <li>Zapobiegaj lub badaj ewentualne nadużycia w związku z Usługą</li>
                <li>ochrony bezpieczeństwa osobistego Użytkowników Serwisu lub społeczeństwa</li>
                <li>Ochrona przed odpowiedzialnością prawną</li>
            </ul>
            <h2>Bezpieczeństwo Twoich danych osobowych</h2><p>Bezpieczeństwo Twoich danych osobowych jest dla Nas ważne,
                ale pamiętaj, że żadna metoda transmisji przez Internet ani metoda elektronicznego przechowywania nie
                jest w 100% bezpieczna. Chociaż staramy się wykorzystywać komercyjnie akceptowalne środki ochrony Twoich
                danych osobowych, nie możemy zagwarantować ich absolutnego bezpieczeństwa.</p><h1>Prywatność dzieci</h1>
            <p>Nasza usługa nie jest skierowana do osób poniżej 13 roku życia. Nie gromadzimy świadomie danych osobowych
                od osób poniżej 13 roku życia. Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje dziecko przekazało
                nam Dane Osobowe, prosimy Skontaktuj się z nami. Jeśli dowiemy się, że zebraliśmy dane osobowe od osób
                poniżej 13 roku życia bez weryfikacji zgody rodziców, podejmujemy kroki w celu usunięcia tych informacji
                z naszych serwerów.</p><p>Jeśli musimy polegać na zgodzie jako podstawie prawnej do przetwarzania Twoich
                danych, a Twój kraj wymaga zgody rodzica, możemy wymagać zgody Twojego rodzica, zanim zbierzemy i
                wykorzystamy te informacje.</p><h1>Linki do innych stron internetowych</h1><p>Nasz Serwis może zawierać
                linki do innych stron internetowych, które nie są przez nas obsługiwane. Jeśli klikniesz link strony
                trzeciej, zostaniesz przekierowany do witryny tej strony trzeciej. Zdecydowanie zalecamy zapoznanie się
                z Polityką prywatności każdej odwiedzanej witryny.</p><p>Nie mamy kontroli nad treścią, polityką
                prywatności ani praktykami witryn lub usług stron trzecich i nie ponosimy za nie odpowiedzialności.</p>
            <h1>Zmiany w niniejszej Polityce prywatności</h1><p>Od czasu do czasu możemy aktualizować naszą Politykę
                prywatności. Powiadomimy Cię o wszelkich zmianach, publikując nową Politykę prywatności na tej
                stronie.</p><p>Powiadomimy Cię za pośrednictwem poczty e-mail i/lub widocznego powiadomienia w naszej
                usłudze, zanim zmiana zacznie obowiązywać i zaktualizujemy datę „Ostatniej aktualizacji” u góry
                niniejszej Polityki prywatności.</p><p>Zaleca się okresowe przeglądanie niniejszej Polityki prywatności
                pod kątem wszelkich zmian. Zmiany w niniejszej Polityce prywatności wchodzą w życie z chwilą ich
                opublikowania na tej stronie.</p><h1>Skontaktuj się z nami</h1><p>Jeśli masz jakiekolwiek pytania
                dotyczące niniejszej Polityki prywatności, możesz skontaktować się z nami:</p>
            <ul>
                <li>E-mailem: {admin_email}</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicyPl;

import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Seo from "../../components/seo/seo.js";
import PrivacyPolicyEs from "../../components/modules/Policy/privacyPolicy/privacyPolicyEs.js";
import PrivacyPolicyEn from "../../components/modules/Policy/privacyPolicy/privacyPolicyEn.js";
import PrivacyPolicyFr from "../../components/modules/Policy/privacyPolicy/privacyPolicyFr.js";
import PrivacyPolicyPt from "../../components/modules/Policy/privacyPolicy/privacyPolicyPt.js";
import PrivacyPolicyIt from "../../components/modules/Policy/privacyPolicy/privacyPolicyIt.js";
import PrivacyPolicyPl from "../../components/modules/Policy/privacyPolicy/privacyPolicyPl.js";

import CookiePolicyEs from "../../components/modules/Policy/cookiePolicy/cookiePolicyEs.js";
import CookiePolicyEn from "../../components/modules/Policy/cookiePolicy/cookiePolicyEn.js";
import CookiePolicyFr from "../../components/modules/Policy/cookiePolicy/cookiePolicyFr.js";
import CookiePolicyPt from "../../components/modules/Policy/cookiePolicy/cookiePolicyPt.js";
import CookiePolicyIt from "../../components/modules/Policy/cookiePolicy/cookiePolicyIt.js";
import CookiePolicyPl from "../../components/modules/Policy/cookiePolicy/cookiePolicyPl.js";

class Policy extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const content = this.props.pageContext.content;

        let dataContent = [];
        dataContent['privatePolicyContent_es'] = PrivacyPolicyEs;
        dataContent['privatePolicyContent_en'] = PrivacyPolicyEn;
        dataContent['privatePolicyContent_fr'] = PrivacyPolicyFr;
        dataContent['privatePolicyContent_pt'] = PrivacyPolicyPt;
        dataContent['privatePolicyContent_it'] = PrivacyPolicyIt;
        dataContent['privatePolicyContent_pl'] = PrivacyPolicyPl;
        dataContent['cookiePolicyContent_es'] = CookiePolicyEs;
        dataContent['cookiePolicyContent_en'] = CookiePolicyEn;
        dataContent['cookiePolicyContent_fr'] = CookiePolicyFr;
        dataContent['cookiePolicyContent_pt'] = CookiePolicyPt;
        dataContent['cookiePolicyContent_it'] = CookiePolicyIt;
        dataContent['cookiePolicyContent_pl'] = CookiePolicyPl;

        const DisplayContent = dataContent[content + "Content_" + seo.lang];

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module bgPr>
                    <Wrapper white>
                        <Column row>
                            <DisplayContent site_name={this.props.pageContext.site_name} site_host={this.props.pageContext.site_host} admin_email={this.props.pageContext.admin_email}/>
                        </Column>
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Policy;
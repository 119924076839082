import React from "react"
import classNames from "classnames"
import "./column.scss"

const Column = props => {
    const classnames = classNames(`m-column`, {
        "m-column--row": props.row,
        "m-column--center": props.centered,
        // width
        "m-column--half": props.col && props.col==="half",
        "m-column--third": props.col && props.col==="third",
        "m-column--forth": props.col && props.col==="forth",
        "m-column--fifth": props.col && props.col==="fifth",
        "m-column--two-fifths": props.col && props.col==="two-fifths",
        "m-column--three-fifths": props.col && props.col==="three-fifths",

        //width complete
        "m-column--third-complete": props.col && props.col==="third-complete",
        "m-column--forth-complete": props.col && props.col==="forth-complete",
        "m-column--fifth-complete": props.col && props.col==="fifth-complete",

        "m-column--text-right": props.textRight,

        "m-column--bg-wgite": props.bgWhite,

        //responsive <767px
        "m-column--sm-complete": props.colSm && props.colSm==="full",
        "m-column--sm-half": props.colSm && props.colSm==="half",
        "m-column--sm-third": props.colSm && props.colSm==="third",
        "m-column--sm-third-complete": props.colSm && props.colSm==="third-complete",
        //<575px
        "m-column--xs-complete": props.colXs && props.colXs==="full",
        "m-column--xs-half": props.colXs && props.colXs==="half",
        "m-column--xs-text-left": props.xsTextLeft,
    });
    return (
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            {props.children}
        </div>
    )
}

export default Column

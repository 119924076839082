import React from "react";

function CookiePolicyPt({site_name, site_host, admin_email}) {
    return (
        <div><h1>Política de Cookies para {site_name}</h1><p>Esta é a Política de Cookies para {site_name} , acessível
            em {site_host}</p><p><strong>O que são cookies</strong></p><p>Como é prática comum em quase
            todos os sites profissionais, este site usa cookies, que são pequenos arquivos que são baixados em seu
            computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as
            usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir
            que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos
            da funcionalidade do site.</p><p><strong>Como usamos cookies</strong></p><p>Usamos cookies por vários
            motivos detalhados abaixo. Infelizmente, na maioria dos casos, não há opções padrão do setor para
            desabilitar cookies sem desabilitar completamente a funcionalidade e os recursos que eles adicionam a este
            site. É recomendável que você deixe em todos os cookies se não tiver certeza se precisa deles ou não, caso
            sejam usados ​​para fornecer um serviço que você usa.</p><p><strong>Desativando cookies</strong></p><p>Você
            pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do seu
            navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a
            funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará
            na desativação de certas funcionalidades e recursos deste site. Portanto, é recomendável que você não
            desative os cookies.</p><p><strong>Os cookies que definimos</strong></p>
            <ul>
                <li><p>Cookies de preferências do site</p><p>Para fornecer a você uma ótima experiência neste site,
                    fornecemos a funcionalidade para definir suas preferências de como este site é executado quando você
                    o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam
                    ser chamadas sempre que você interagir com uma página afetada por suas preferências.</p></li>
            </ul>
            <p><strong>Cookies de terceiros</strong></p><p>Em alguns casos especiais, também usamos cookies fornecidos
                por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar por
                meio deste site.</p>
            <ul>
                <li><p>De tempos em tempos, testamos novos recursos e fazemos mudanças sutis na forma como o site é
                    entregue. Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para
                    garantir que você receba uma experiência consistente enquanto estiver no site, ao mesmo tempo em que
                    entendemos quais otimizações nossos usuários mais apreciam.</p></li>
                <li><p>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para
                    veicular anúncios mais relevantes na web e limitar o número de vezes que um determinado anúncio é
                    exibido para você.</p><p>Para obter mais informações sobre o Google AdSense, consulte as Perguntas
                    frequentes sobre privacidade oficial do Google AdSense.</p></li>
            </ul>
            <p><strong>Mais Informações</strong></p><p>Espero que tenha esclarecido as coisas para você e, como foi
                mencionado anteriormente, se houver algo que você não tenha certeza se precisa ou não, geralmente é mais
                seguro deixar os cookies ativados caso ele interaja com um dos recursos que você usa em nosso site.</p>
            <p>No entanto, se ainda estiver à procura de mais informações, pode contactar-nos através de um dos nossos
                métodos de contacto preferidos:</p>
            <ul>
                <li>E-mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyPt;

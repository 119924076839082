import React from "react";

function PrivacyPolicyIt({site_name, site_host, admin_email}) {
    return (
        <div><h1>Privacy Policy</h1><p>Ultimo aggiornamento: 17 marzo 2022</p><p>Questa Informativa sulla
            privacy descrive le nostre politiche e procedure sulla raccolta, l'uso e la divulgazione delle tue
            informazioni quando utilizzi il Servizio e ti informa sui tuoi diritti alla privacy e su come la legge ti
            protegge.</p><p>Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio. Utilizzando il
            Servizio, accetti la raccolta e l'utilizzo delle informazioni in conformità con la presente Informativa
            sulla privacy.</p><h1>Interpretazione e definizioni</h1><h2>Interpretazione</h2><p>Le parole di cui la
            lettera iniziale è maiuscola hanno significati definiti nelle seguenti condizioni. Le seguenti definizioni
            avranno lo stesso significato indipendentemente dal fatto che appaiano al singolare o al plurale.</p>
            <h2>Definizioni</h2><p>Ai fini della presente Informativa sulla privacy:</p>
            <ul>
                <li><p><strong>Account</strong> indica un account unico creato per consentire all'utente di accedere al
                    nostro Servizio o a parti del nostro Servizio.</p></li>
                <li><p><strong>Società</strong> (denominata "la Società", "Noi", "Ci" o "Nostro" nel presente Accordo)
                    si riferisce a {site_name} .</p></li>
                <li><p><strong>I cookie</strong> sono piccoli file che vengono inseriti sul tuo computer, dispositivo
                    mobile o qualsiasi altro dispositivo da un sito Web, contenenti i dettagli della tua cronologia di
                    navigazione su quel sito Web tra i suoi numerosi usi.</p></li>
                <li><p><strong>Dispositivo</strong> indica qualsiasi dispositivo che può accedere al Servizio come un
                    computer, un cellulare o un tablet digitale.</p></li>
                <li><p><strong>I Dati Personali sono</strong> qualsiasi informazione relativa a una persona fisica
                    identificata o identificabile.</p></li>
                <li><p><strong>Il servizio</strong> si riferisce al Sito.</p></li>
                <li><p><strong>Fornitore di servizi</strong> indica qualsiasi persona fisica o giuridica che elabora i
                    dati per conto della Società. Si riferisce a società terze o persone alle dipendenze della Società
                    per facilitare il Servizio, per fornire il Servizio per conto della Società, per eseguire servizi
                    relativi al Servizio o per assistere la Società nell'analisi delle modalità di utilizzo del
                    Servizio.</p></li>
                <li><p><strong>Per Dati di utilizzo</strong> si intendono i dati raccolti automaticamente, generati
                    dall'utilizzo del Servizio o dall'infrastruttura del Servizio stesso (ad esempio, la durata della
                    visita di una pagina).</p></li>
                <li><p><strong>Il sito Web</strong> fa riferimento a {site_name} , accessibile da {site_host}
                </p></li>
                <li><p><strong>Si</strong> intende la persona fisica che accede o utilizza il Servizio, o la società o
                    altra persona giuridica per conto della quale tale persona accede o utilizza il Servizio, a seconda
                    dei casi.</p></li>
            </ul>
            <h1>Raccolta e utilizzo dei tuoi dati personali</h1><h2>Tipi di dati raccolti</h2><h3>Dati personali</h3>
            <p>Durante l'utilizzo del nostro servizio, potremmo chiederti di fornirci alcune informazioni di
                identificazione personale che possono essere utilizzate per contattarti o identificarti. Le informazioni
                di identificazione personale possono includere, ma non sono limitate a:</p>
            <ul>
                <li>Dati di utilizzo</li>
            </ul>
            <h3>Dati di utilizzo</h3><p>I Dati di utilizzo vengono raccolti automaticamente durante l'utilizzo del
                Servizio.</p><p>I Dati di utilizzo possono includere informazioni come l'indirizzo del protocollo
                Internet del tuo dispositivo (ad es. indirizzo IP), il tipo di browser, la versione del browser, le
                pagine del nostro Servizio che visiti, l'ora e la data della tua visita, il tempo trascorso su tali
                pagine, dispositivo unico identificatori e altri dati diagnostici.</p><p>Quando accedi al Servizio da o
                tramite un dispositivo mobile, potremmo raccogliere alcune informazioni automaticamente, inclusi, a
                titolo esemplificativo, il tipo di dispositivo mobile che utilizzi, l'ID univoco del tuo dispositivo
                mobile, l'indirizzo IP del tuo dispositivo mobile, sistema operativo, il tipo di browser Internet mobile
                utilizzato, gli identificatori univoci del dispositivo e altri dati diagnostici.</p><p>Potremmo anche
                raccogliere informazioni che il tuo browser invia ogni volta che visiti il ​​nostro Servizio o quando
                accedi al Servizio da o tramite un dispositivo mobile.</p><h3>Tecnologie di tracciamento e cookie</h3>
            <p>Utilizziamo cookie e tecnologie di tracciamento simili per tracciare l'attività sul nostro servizio e
                memorizzare determinate informazioni. Le tecnologie di tracciamento utilizzate sono beacon, tag e script
                per raccogliere e tenere traccia delle informazioni e per migliorare e analizzare il nostro servizio. Le
                tecnologie che utilizziamo possono includere:</p>
            <ul>
                <li><strong>Cookie o cookie del browser. </strong>Un cookie è un piccolo file posizionato sul tuo
                    dispositivo. Puoi indicare al tuo browser di rifiutare tutti i cookie o di indicare quando viene
                    inviato un cookie. Tuttavia, se non accetti i cookie, potresti non essere in grado di utilizzare
                    alcune parti del nostro servizio. A meno che tu non abbia regolato le impostazioni del tuo browser
                    in modo che rifiuti i cookie, il nostro servizio potrebbe utilizzare i cookie.
                </li>
                <li><strong>Biscotti Flash. </strong>Alcune funzionalità del nostro Servizio possono utilizzare oggetti
                    memorizzati localmente (o Flash Cookie) per raccogliere e memorizzare informazioni sulle tue
                    preferenze o sulla tua attività sul nostro Servizio. I cookie flash non sono gestiti dalle stesse
                    impostazioni del browser utilizzate per i cookie del browser. Per ulteriori informazioni su come
                    eliminare i cookie Flash, leggere "Dove posso modificare le impostazioni per la disabilitazione o
                    l'eliminazione di oggetti condivisi locali?" disponibile su
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li><strong>Web Beacon. </strong>Alcune sezioni del nostro Servizio e delle nostre e-mail possono
                    contenere piccoli file elettronici noti come web beacon (chiamati anche clear gif, pixel tag e
                    single-pixel gif) che consentono alla Società, ad esempio, di contare gli utenti che hanno visitato
                    quelle pagine o aperto un'e-mail e per altre statistiche del sito Web correlate (ad esempio,
                    registrazione della popolarità di una determinata sezione e verifica dell'integrità del sistema e
                    del server).
                </li>
            </ul>
            <p>I cookie possono essere cookie "persistenti" o "di sessione". I cookie persistenti rimangono sul tuo
                personal computer o dispositivo mobile quando vai offline, mentre i cookie di sessione vengono eliminati
                non appena chiudi il tuo browser web.</p><p>Utilizziamo sia i cookie di sessione che quelli persistenti
                per le finalità indicate di seguito:</p>
            <ul>
                <li><p><strong>Cookie necessari/essenziali</strong></p><p>Tipo: Cookie di sessione</p><p>Amministrato
                    da: Noi</p><p>Scopo: questi cookie sono essenziali per fornirti i servizi disponibili attraverso il
                    sito Web e per consentirti di utilizzare alcune delle sue funzionalità. Aiutano ad autenticare gli
                    utenti e prevenire l'uso fraudolento degli account utente. Senza questi cookie, i servizi che hai
                    richiesto non possono essere forniti e utilizziamo questi cookie solo per fornirti tali servizi.</p>
                </li>
                <li><p><strong>Cookie Policy/Accettazione Avviso Cookie</strong></p><p>Tipo: Cookie persistenti</p>
                    <p>Amministrato da: Noi</p><p>Scopo: questi cookie identificano se gli utenti hanno accettato l'uso
                        dei cookie sul sito web.</p></li>
                <li><p><strong>Cookie di funzionalità</strong></p><p>Tipo: Cookie persistenti</p><p>Amministrato da:
                    Noi</p><p>Scopo: questi cookie ci consentono di ricordare le scelte che fai quando utilizzi il sito
                    Web, come ricordare i tuoi dati di accesso o la preferenza della lingua. Lo scopo di questi cookie è
                    quello di fornirti un'esperienza più personale ed evitare che tu debba reinserire le tue preferenze
                    ogni volta che utilizzi il sito web.</p></li>
            </ul>
            <p>Per ulteriori informazioni sui cookie che utilizziamo e le tue scelte in merito ai cookie, visita la
                nostra Politica sui cookie o la sezione Cookie della nostra Politica sulla privacy.</p><h2>Utilizzo dei
                tuoi dati personali</h2><p>La Società può utilizzare i Dati Personali per le seguenti finalità:</p>
            <ul>
                <li><p><strong>Per fornire e mantenere il nostro Servizio</strong> , incluso per monitorare l'utilizzo
                    del nostro Servizio.</p></li>
                <li><p><strong>Per gestire il Tuo Account:</strong> per gestire la Tua registrazione come utente del
                    Servizio. I Dati Personali che fornisci possono darti accesso a diverse funzionalità del Servizio
                    che sono a tua disposizione come utente registrato.</p></li>
                <li><p><strong>Per l'esecuzione di un contratto:</strong> lo sviluppo, la conformità e l'esecuzione del
                    contratto di acquisto per i prodotti, articoli o servizi che hai acquistato o di qualsiasi altro
                    contratto con Noi tramite il Servizio.</p></li>
                <li><p><strong>Per contattarti:</strong> Per contattarti tramite e-mail, telefonate, SMS o altre forme
                    equivalenti di comunicazione elettronica, come le notifiche push di un'applicazione mobile
                    riguardanti aggiornamenti o comunicazioni informative relative alle funzionalità, ai prodotti o ai
                    servizi appaltati, inclusi gli aggiornamenti di sicurezza, quando necessario o ragionevole per la
                    loro attuazione.</p></li>
                <li><p><strong>Per fornirti</strong> notizie, offerte speciali e informazioni generali su altri beni,
                    servizi ed eventi che offriamo simili a quelli che hai già acquistato o per i quali hai già chiesto
                    informazioni, a meno che tu non abbia scelto di non ricevere tali informazioni.</p></li>
                <li><p><strong>Per gestire le tue richieste:</strong> Per partecipare e gestirci le tue richieste.</p>
                </li>
                <li><p><strong>Per i trasferimenti di affari:</strong> potremmo utilizzare le tue informazioni per
                    valutare o condurre una fusione, dismissione, ristrutturazione, riorganizzazione, scioglimento o
                    altra vendita o trasferimento di alcuni o tutti i nostri beni, sia come azienda in attività che come
                    parte di fallimento, liquidazione, o un procedimento simile, in cui i Dati Personali da noi detenuti
                    sugli utenti del nostro Servizio sono tra i beni trasferiti.</p></li>
                <li><p><strong>Per altri scopi</strong> : potremmo utilizzare le tue informazioni per altri scopi, come
                    l'analisi dei dati, l'identificazione delle tendenze di utilizzo, la determinazione dell'efficacia
                    delle nostre campagne promozionali e per valutare e migliorare il nostro Servizio, prodotti,
                    servizi, marketing e la tua esperienza.</p></li>
            </ul>
            <p>Potremmo condividere le tue informazioni personali nelle seguenti situazioni:</p>
            <ul>
                <li><strong>Con i fornitori di servizi:</strong> potremmo condividere le tue informazioni personali con
                    i fornitori di servizi per monitorare e analizzare l'uso del nostro servizio, per contattarti.
                </li>
                <li><strong>Per i trasferimenti di affari:</strong> potremmo condividere o trasferire le tue
                    informazioni personali in relazione a, o durante le negoziazioni di, qualsiasi fusione, vendita di
                    beni della Società, finanziamento o acquisizione di tutta o parte della nostra attività a un'altra
                    società.
                </li>
                <li><strong>Con gli affiliati:</strong> potremmo condividere le tue informazioni con i nostri affiliati,
                    nel qual caso richiederemo a tali affiliati di rispettare la presente Informativa sulla privacy. Gli
                    affiliati includono la nostra società madre e qualsiasi altra sussidiaria, partner di joint venture
                    o altre società che controlliamo o che sono sotto il controllo comune con noi.
                </li>
                <li><strong>Con i partner commerciali:</strong> potremmo condividere le tue informazioni con i nostri
                    partner commerciali per offrirti determinati prodotti, servizi o promozioni.
                </li>
                <li><strong>Con altri utenti:</strong> quando condividi informazioni personali o interagisci in altro
                    modo nelle aree pubbliche con altri utenti, tali informazioni possono essere visualizzate da tutti
                    gli utenti e possono essere distribuite pubblicamente all'esterno.
                </li>
                <li><strong>Con il tuo consenso</strong> : potremmo divulgare le tue informazioni personali per
                    qualsiasi altro scopo con il tuo consenso.
                </li>
            </ul>
            <h2>Conservazione dei tuoi dati personali</h2><p>La Società conserverà i tuoi Dati Personali solo per il
                tempo necessario agli scopi indicati nella presente Informativa sulla privacy. Conserveremo e
                utilizzeremo i tuoi dati personali nella misura necessaria per adempiere ai nostri obblighi legali (ad
                esempio, se siamo tenuti a conservare i tuoi dati per rispettare le leggi applicabili), risolvere
                controversie e far rispettare i nostri accordi legali e le nostre politiche.</p><p>La Società conserverà
                anche i Dati di utilizzo per scopi di analisi interna. I Dati di utilizzo vengono generalmente
                conservati per un periodo di tempo più breve, tranne quando questi dati vengono utilizzati per
                rafforzare la sicurezza o per migliorare la funzionalità del Nostro Servizio, o quando siamo legalmente
                obbligati a conservare questi dati per periodi di tempo più lunghi.</p><h2>Trasferimento dei tuoi dati
                personali</h2><p>I tuoi dati, inclusi i Dati Personali, sono trattati presso le sedi operative della
                Società e in ogni altro luogo in cui si trovano le parti coinvolte nel trattamento. Significa che queste
                informazioni possono essere trasferite e mantenute su computer situati al di fuori del tuo stato,
                provincia, paese o altra giurisdizione governativa in cui le leggi sulla protezione dei dati potrebbero
                differire da quelle della tua giurisdizione.</p><p>Il tuo consenso alla presente Informativa sulla
                privacy seguito dall'invio di tali informazioni rappresenta il tuo consenso a tale trasferimento.</p>
            <p>La Società adotterà tutte le misure ragionevolmente necessarie per garantire che i tuoi dati siano
                trattati in modo sicuro e in conformità con la presente Informativa sulla privacy e nessun trasferimento
                dei tuoi dati personali avverrà a un'organizzazione o a un paese a meno che non siano in atto controlli
                adeguati, inclusa la sicurezza di I tuoi dati e altre informazioni personali.</p><h2>Divulgazione dei
                tuoi dati personali</h2><h3>Transazione d'affari</h3><p>Se la Società è coinvolta in una fusione,
                acquisizione o vendita di asset, i tuoi Dati Personali potrebbero essere trasferiti. Forniremo un avviso
                prima che i tuoi Dati Personali vengano trasferiti e diventino soggetti a una diversa Informativa sulla
                privacy.</p><h3>Forze dell'ordine</h3><p>In determinate circostanze, la Società potrebbe essere
                obbligata a divulgare i tuoi Dati Personali se richiesto dalla legge o in risposta a richieste valide da
                parte di autorità pubbliche (ad esempio un tribunale o un ente governativo).</p><h3>Altri requisiti
                legali</h3><p>La Società può divulgare i tuoi Dati Personali in buona fede, ritenendo che tale azione
                sia necessaria per:</p>
            <ul>
                <li>Rispettare un obbligo legale</li>
                <li>Proteggere e difendere i diritti o la proprietà della Società</li>
                <li>Prevenire o indagare su possibili illeciti in relazione al Servizio</li>
                <li>Proteggere l'incolumità personale degli Utenti del Servizio o del pubblico</li>
                <li>Proteggere dalla responsabilità legale</li>
            </ul>
            <h2>Sicurezza dei tuoi dati personali</h2><p>La sicurezza dei tuoi dati personali è importante per noi, ma
                ricorda che nessun metodo di trasmissione su Internet o metodo di archiviazione elettronica è sicuro al
                100%. Sebbene ci sforziamo di utilizzare mezzi commercialmente accettabili per proteggere i tuoi dati
                personali, non possiamo garantirne la sicurezza assoluta.</p><h1>Privacy dei bambini</h1><p>Il nostro
                servizio non si rivolge a persone di età inferiore ai 13 anni. Non raccogliamo consapevolmente
                informazioni di identificazione personale da persone di età inferiore ai 13 anni. Se sei un genitore o
                tutore e sei consapevole che tuo figlio ci ha fornito dati personali, ti preghiamo di Contattaci. Se
                veniamo a conoscenza del fatto che abbiamo raccolto Dati personali da persone di età inferiore ai 13
                anni senza la verifica del consenso dei genitori, adottiamo misure per rimuovere tali informazioni dai
                Nostri server.</p><p>Se dobbiamo fare affidamento sul consenso come base legale per l'elaborazione delle
                tue informazioni e il tuo paese richiede il consenso di un genitore, potremmo richiedere il consenso dei
                tuoi genitori prima di raccogliere e utilizzare tali informazioni.</p><h1>Collegamenti ad altri siti
                web</h1><p>Il nostro servizio può contenere collegamenti ad altri siti Web che non sono gestiti da noi.
                Se fai clic su un collegamento di terze parti, verrai indirizzato al sito di tale terza parte. Ti
                consigliamo vivamente di rivedere l'Informativa sulla privacy di ogni sito che visiti.</p><p>Non abbiamo
                alcun controllo e non ci assumiamo alcuna responsabilità per il contenuto, le politiche sulla privacy o
                le pratiche di siti o servizi di terze parti.</p><h1>Modifiche alla presente Informativa sulla
                privacy</h1><p>Potremmo aggiornare la nostra Informativa sulla privacy di volta in volta. Ti informeremo
                di eventuali modifiche pubblicando la nuova Privacy Policy in questa pagina.</p><p>Ti informeremo via
                e-mail e/o un avviso in evidenza sul Nostro Servizio, prima che la modifica diventi effettiva e
                aggiorneremo la data "Ultimo aggiornamento" nella parte superiore della presente Informativa sulla
                privacy.</p><p>Si consiglia di rivedere periodicamente la presente Informativa sulla privacy per
                eventuali modifiche. Le modifiche alla presente Informativa sulla privacy sono efficaci quando vengono
                pubblicate su questa pagina.</p><h1>Contattaci</h1><p>In caso di domande sulla presente Informativa
                sulla privacy, puoi contattarci:</p>
            <ul>
                <li>Per e-mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicyIt;

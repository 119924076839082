import React from "react";

function PrivacyPolicyPt({site_name, site_host, admin_email}) {
    return (
        <div><h1>Política de Privacidade</h1><p>Última atualização: 13 de março de 2022</p><p>Esta Política de
            Privacidade descreve Nossas políticas e procedimentos sobre a coleta, uso e divulgação de Suas informações
            quando Você usa o Serviço e informa sobre Seus direitos de privacidade e como a lei protege Você.</p>
            <p>Usamos seus dados pessoais para fornecer e melhorar o serviço. Ao usar o Serviço, você concorda com a
                coleta e uso de informações de acordo com esta Política de Privacidade.</p><h1>Interpretação e
                Definições</h1><h2>Interpretação</h2><p>As palavras cuja letra inicial é maiúscula têm significados
                definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente
                de aparecerem no singular ou no plural.</p><h2>Definições</h2><p>Para efeitos desta Política de
                Privacidade:</p>
            <ul>
                <li><p><strong>Conta</strong> significa uma conta exclusiva criada para você acessar nosso Serviço ou
                    partes de nosso Serviço.</p></li>
                <li><p><strong>Empresa</strong> (referida como "a Empresa", "Nós", "Nós" ou "Nosso" neste Contrato)
                    refere-se a {site_name} .</p></li>
                <li><p><strong>Cookies</strong> são pequenos arquivos que são colocados em seu computador, dispositivo
                    móvel ou qualquer outro dispositivo por um site, contendo os detalhes de seu histórico de navegação
                    nesse site entre seus diversos usos.</p></li>
                <li><p><strong>País</strong> se refere a: Espanha</p></li>
                <li><p><strong>Dispositivo</strong> significa qualquer dispositivo que possa acessar o Serviço, como um
                    computador, um celular ou um tablet digital.</p></li>
                <li><p><strong>Dados Pessoais</strong> são quaisquer informações relacionadas a um indivíduo
                    identificado ou identificável.</p></li>
                <li><p><strong>Serviço</strong> refere-se ao Site.</p></li>
                <li><p><strong>Prestador de Serviços</strong> significa qualquer pessoa física ou jurídica que processe
                    os dados em nome da Empresa. Refere-se a empresas terceirizadas ou indivíduos empregados pela
                    Empresa para facilitar o Serviço, fornecer o Serviço em nome da Empresa, realizar serviços
                    relacionados ao Serviço ou ajudar a Empresa a analisar como o Serviço é usado.</p></li>
                <li><p><strong>Dados de uso</strong> referem-se a dados coletados automaticamente, gerados pelo uso do
                    Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).</p>
                </li>
                <li><p><strong>Site</strong> refere-se a {site_name} , acessível em {site_host}</p></li>
                <li><p><strong>Você</strong> significa o indivíduo acessando ou usando o Serviço, ou a empresa ou outra
                    entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.
                </p></li>
            </ul>
            <h1>Coletando e usando seus dados pessoais</h1><h2>Tipos de dados coletados</h2><h3>Dados pessoais</h3><p>Ao
                usar nosso Serviço, podemos solicitar que você nos forneça determinadas informações de identificação
                pessoal que podem ser usadas para contatá-lo ou identificá-lo. As informações de identificação pessoal
                podem incluir, mas não se limitam a:</p>
            <ul>
                <li>Dados de uso</li>
            </ul>
            <h3>Dados de uso</h3><p>Os Dados de Uso são coletados automaticamente ao usar o Serviço.</p><p>Os dados de
                uso podem incluir informações como o endereço do protocolo de Internet do seu dispositivo (por exemplo,
                endereço IP), tipo de navegador, versão do navegador, as páginas do nosso serviço que você visita, a
                hora e a data de sua visita, o tempo gasto nessas páginas, dispositivo exclusivo identificadores e
                outros dados de diagnóstico.</p><p>Quando você acessa o Serviço por ou por meio de um dispositivo móvel,
                podemos coletar determinadas informações automaticamente, incluindo, mas não se limitando ao tipo de
                dispositivo móvel que você usa, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu
                dispositivo móvel, o seu sistema operacional, o tipo de navegador de Internet móvel que você usa,
                identificadores exclusivos de dispositivo e outros dados de diagnóstico.</p><p>Também podemos coletar
                informações que seu navegador envia sempre que você visita nosso Serviço ou quando acessa o Serviço por
                ou por meio de um dispositivo móvel.</p><h3>Tecnologias de rastreamento e cookies</h3><p>Usamos cookies
                e tecnologias de rastreamento semelhantes para rastrear a atividade em nosso serviço e armazenar
                determinadas informações. As tecnologias de rastreamento usadas são beacons, tags e scripts para coletar
                e rastrear informações e para melhorar e analisar nosso serviço. As tecnologias que usamos podem
                incluir:</p>
            <ul>
                <li><strong>Cookies ou Cookies do navegador. </strong>Um cookie é um pequeno arquivo colocado em seu
                    dispositivo. Você pode instruir seu navegador a recusar todos os cookies ou a indicar quando um
                    cookie está sendo enviado. No entanto, se você não aceitar cookies, talvez não consiga usar algumas
                    partes do nosso serviço. A menos que você tenha ajustado a configuração do seu navegador para
                    recusar cookies, nosso Serviço pode usar cookies.
                </li>
                <li><strong>Biscoitos Flash. </strong>Certos recursos de nosso Serviço podem usar objetos armazenados
                    localmente (ou Flash Cookies) para coletar e armazenar informações sobre Suas preferências ou Sua
                    atividade em nosso Serviço. Os Flash Cookies não são gerenciados pelas mesmas configurações do
                    navegador usadas para os cookies do navegador. Para obter mais informações sobre como você pode
                    excluir Flash Cookies, leia "Onde posso alterar as configurações para desabilitar ou excluir objetos
                    compartilhados locais?" disponível em
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li><strong>Web Beacons. </strong>Certas seções de nosso Serviço e nossos e-mails podem conter pequenos
                    arquivos eletrônicos conhecidos como web beacons (também chamados de clear gifs, pixel tags e
                    single-pixel gifs) que permitem que a Empresa, por exemplo, conte os usuários que visitaram essas
                    páginas ou abriu um e-mail e outras estatísticas relacionadas ao site (por exemplo, registrando a
                    popularidade de uma determinada seção e verificando a integridade do sistema e do servidor).
                </li>
            </ul>
            <p>Os cookies podem ser Cookies "Persistentes" ou "Sessão". Os cookies persistentes permanecem em seu
                computador pessoal ou dispositivo móvel quando você fica offline, enquanto os cookies de sessão são
                excluídos assim que você fecha seu navegador.</p><p>Usamos Cookies de Sessão e Persistentes para os fins
                descritos abaixo:</p>
            <ul>
                <li><p><strong>Cookies Necessários / Essenciais</strong></p><p>Tipo: Cookies de sessão</p>
                    <p>Administrado por: Nós</p><p>Finalidade: Esses cookies são essenciais para fornecer a você os
                        serviços disponíveis no site e permitir que você use alguns de seus recursos. Eles ajudam a
                        autenticar usuários e evitar o uso fraudulento de contas de usuários. Sem esses cookies, os
                        serviços que você solicitou não podem ser fornecidos, e nós usamos esses cookies apenas para
                        fornecer esses serviços a você.</p></li>
                <li><p><strong>Política de Cookies / Cookies de Aceitação de Avisos</strong></p><p>Tipo: Cookies
                    persistentes</p><p>Administrado por: Nós</p><p>Finalidade: Esses Cookies identificam se os usuários
                    aceitaram o uso de cookies no Site.</p></li>
                <li><p><strong>Cookies de funcionalidade</strong></p><p>Tipo: Cookies persistentes</p><p>Administrado
                    por: Nós</p><p>Finalidade: Esses cookies nos permitem lembrar as escolhas que você faz quando usa o
                    site, como lembrar seus detalhes de login ou preferência de idioma. O objetivo desses cookies é
                    fornecer a você uma experiência mais pessoal e evitar que você precise inserir novamente suas
                    preferências toda vez que usar o site.</p></li>
            </ul>
            <p>Para obter mais informações sobre os cookies que usamos e suas escolhas em relação aos cookies, visite
                nossa Política de Cookies ou a seção Cookies de nossa Política de Privacidade.</p><h2>Uso de seus dados
                pessoais</h2><p>A Empresa pode usar os Dados Pessoais para os seguintes fins:</p>
            <ul>
                <li><p><strong>Para fornecer e manter nosso Serviço</strong> , inclusive para monitorar o uso de nosso
                    Serviço.</p></li>
                <li><p><strong>Para gerenciar Sua Conta:</strong> para gerenciar Seu registro como usuário do Serviço.
                    Os Dados Pessoais que você fornece podem dar a Você acesso a diferentes funcionalidades do Serviço
                    que estão disponíveis para Você como usuário registrado.</p></li>
                <li><p><strong>Para a execução de um contrato:</strong> o desenvolvimento, cumprimento e realização do
                    contrato de compra dos produtos, itens ou serviços que Você adquiriu ou de qualquer outro contrato
                    conosco através do Serviço.</p></li>
                <li><p><strong>Para entrar em contato com você:</strong> Para entrar em contato com você por e-mail,
                    telefone, SMS ou outras formas equivalentes de comunicação eletrônica, como notificações push de um
                    aplicativo móvel sobre atualizações ou comunicações informativas relacionadas às funcionalidades,
                    produtos ou serviços contratados, incluindo as atualizações de segurança, quando necessário ou
                    razoável para sua implementação.</p></li>
                <li><p><strong>Para fornecer a Você</strong> notícias, ofertas especiais e informações gerais sobre
                    outros bens, serviços e eventos que oferecemos que são semelhantes àqueles que você já comprou ou
                    perguntou, a menos que você tenha optado por não receber tais informações.</p></li>
                <li><p><strong>Para gerenciar seus pedidos:</strong> Para atender e gerenciar seus pedidos para nós.</p>
                </li>
                <li><p><strong>Para transferências de negócios:</strong> Podemos usar suas informações para avaliar ou
                    conduzir uma fusão, alienação, reestruturação, reorganização, dissolução ou outra venda ou
                    transferência de alguns ou de todos os nossos ativos, seja em continuidade ou como parte de
                    falência, liquidação, ou processo semelhante, no qual os Dados Pessoais detidos por Nós sobre os
                    usuários do nosso Serviço estão entre os ativos transferidos.</p></li>
                <li><p><strong>Para outros fins</strong> : Podemos usar suas informações para outros fins, como análise
                    de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas
                    promocionais e para avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua
                    experiência.</p></li>
            </ul>
            <p>Podemos compartilhar suas informações pessoais nas seguintes situações:</p>
            <ul>
                <li><strong>Com Prestadores de Serviços:</strong> Podemos compartilhar suas informações pessoais com
                    Prestadores de Serviços para monitorar e analisar o uso de nosso Serviço, para entrar em contato com
                    Você.
                </li>
                <li><strong>Para transferências de negócios:</strong> Podemos compartilhar ou transferir Suas
                    informações pessoais em conexão com ou durante negociações de qualquer fusão, venda de ativos da
                    Empresa, financiamento ou aquisição de todo ou parte de Nossos negócios para outra empresa.
                </li>
                <li><strong>Com Afiliados:</strong> Podemos compartilhar Suas informações com Nossos afiliados, caso em
                    que exigiremos que esses afiliados honrem esta Política de Privacidade. As afiliadas incluem nossa
                    empresa controladora e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas
                    que controlamos ou que estão sob controle comum conosco.
                </li>
                <li><strong>Com parceiros de negócios:</strong> Podemos compartilhar suas informações com nossos
                    parceiros de negócios para oferecer a você determinados produtos, serviços ou promoções.
                </li>
                <li><strong>Com outros usuários:</strong> quando você compartilha informações pessoais ou interage de
                    outra forma nas áreas públicas com outros usuários, essas informações podem ser visualizadas por
                    todos os usuários e podem ser distribuídas publicamente para fora.
                </li>
                <li><strong>Com seu consentimento</strong> : Podemos divulgar suas informações pessoais para qualquer
                    outro propósito com seu consentimento.
                </li>
            </ul>
            <h2>Retenção de seus dados pessoais</h2><p>A Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário
                para os fins estabelecidos nesta Política de Privacidade. Nós reteremos e usaremos Seus Dados Pessoais
                na medida necessária para cumprir nossas obrigações legais (por exemplo, se formos obrigados a reter
                seus dados para cumprir as leis aplicáveis), resolver disputas e fazer cumprir nossos acordos e
                políticas legais.</p><p>A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de
                Uso são geralmente retidos por um período de tempo mais curto, exceto quando esses dados são usados
                ​​para fortalecer a segurança ou melhorar a funcionalidade de Nosso Serviço, ou somos legalmente
                obrigados a reter esses dados por períodos de tempo mais longos.</p><h2>Transferência de seus dados
                pessoais</h2><p>Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais
                da Empresa e em quaisquer outros locais onde as partes envolvidas no processamento estejam localizadas.
                Isso significa que essas informações podem ser transferidas para – e mantidas em – computadores
                localizados fora de Seu estado, província, país ou outra jurisdição governamental onde as leis de
                proteção de dados podem diferir daquelas de Sua jurisdição.</p><p>Seu consentimento para esta Política
                de Privacidade seguido de Seu envio de tais informações representa Seu acordo com essa
                transferência.</p><p>A Empresa tomará todas as medidas razoavelmente necessárias para garantir que Seus
                dados sejam tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência
                de Seus Dados Pessoais ocorrerá para uma organização ou país, a menos que haja controles adequados,
                incluindo a segurança de Seus dados e outras informações pessoais.</p><h2>Divulgação de seus dados
                pessoais</h2><h3>Transações comerciais</h3><p>Se a Empresa estiver envolvida em uma fusão, aquisição ou
                venda de ativos, Seus Dados Pessoais poderão ser transferidos. Forneceremos um aviso antes que Seus
                Dados Pessoais sejam transferidos e fiquem sujeitos a uma Política de Privacidade diferente.</p>
            <h3>Aplicação da lei</h3><p>Sob certas circunstâncias, a Empresa pode ser obrigada a divulgar Seus Dados
                Pessoais se exigido por lei ou em resposta a solicitações válidas de autoridades públicas (por exemplo,
                um tribunal ou agência governamental).</p><h3>Outros requisitos legais</h3><p>A Empresa pode divulgar
                Seus Dados Pessoais acreditando de boa fé que tal ação é necessária para:</p>
            <ul>
                <li>Cumprir uma obrigação legal</li>
                <li>Proteger e defender os direitos ou propriedade da Empresa</li>
                <li>Prevenir ou investigar possíveis irregularidades relacionadas ao Serviço</li>
                <li>Proteger a segurança pessoal dos Usuários do Serviço ou do público</li>
                <li>Proteja-se contra responsabilidade legal</li>
            </ul>
            <h2>Segurança de seus dados pessoais</h2><p>A segurança de seus dados pessoais é importante para nós, mas
                lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100%
                seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis ​​para proteger seus dados
                pessoais, não podemos garantir sua segurança absoluta.</p><h1>Privacidade infantil</h1><p>Nosso Serviço
                não se dirige a menores de 13 anos. Não coletamos intencionalmente informações de identificação pessoal
                de menores de 13 anos. Se Você é pai ou responsável e está ciente de que Seu filho nos forneceu Dados
                Pessoais, por favor Contate-Nos. Se tomarmos conhecimento de que coletamos Dados Pessoais de menores de
                13 anos sem verificação do consentimento dos pais, tomaremos medidas para remover essas informações de
                nossos servidores.</p><p>Se precisarmos de consentimento como base legal para processar Suas informações
                e Seu país exigir o consentimento de um dos pais, poderemos exigir o consentimento de seus pais antes de
                coletarmos e usarmos essas informações.</p><h1>Links para outros sites</h1><p>Nosso Serviço pode conter
                links para outros sites que não são operados por Nós. Se você clicar em um link de terceiros, será
                direcionado ao site desse terceiro. Recomendamos fortemente que você revise a Política de Privacidade de
                cada site que você visita.</p><p>Não temos controle e não assumimos responsabilidade pelo conteúdo,
                políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</p><h1>Alterações a
                esta Política de Privacidade</h1><p>Podemos atualizar nossa Política de Privacidade de tempos em tempos.
                Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade nesta
                página.</p><p>Informaremos Você por e-mail e/ou um aviso em destaque em Nosso Serviço, antes que a
                alteração entre em vigor e atualize a data da "Última atualização" na parte superior desta Política de
                Privacidade.</p><p>Você é aconselhado a revisar esta Política de Privacidade periodicamente para
                quaisquer alterações. As alterações a esta Política de Privacidade são efetivas quando publicadas nesta
                página.</p><h1>Contate-Nos</h1><p>Se você tiver alguma dúvida sobre esta Política de Privacidade, você
                pode entrar em contato conosco:</p>
            <ul>
                <li>Por email: {admin_email}</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicyPt;

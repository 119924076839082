import React from "react";

function CookiePolicyFr({site_name, site_host, admin_email}) {
    return (
        <div><h1>Politique relative aux cookies pour {site_name}</h1><p>Ceci est la politique en matière de cookies pour
            {site_name} , accessible depuis {site_host}</p><p><strong>Que sont les cookies</strong></p>
            <p>Comme c'est la pratique courante avec presque tous les sites Web professionnels, ce site utilise des
                cookies, qui sont de petits fichiers téléchargés sur votre ordinateur, pour améliorer votre expérience.
                Cette page décrit les informations qu'ils recueillent, comment nous les utilisons et pourquoi nous avons
                parfois besoin de stocker ces cookies. Nous partagerons également comment vous pouvez empêcher le
                stockage de ces cookies, mais cela peut dégrader ou "casser" certains éléments de la fonctionnalité des
                sites.</p><p><strong>Comment nous utilisons les cookies</strong></p><p>Nous utilisons des cookies pour
                diverses raisons détaillées ci-dessous. Malheureusement, dans la plupart des cas, il n'existe pas
                d'options standard de l'industrie pour désactiver les cookies sans désactiver complètement les
                fonctionnalités et fonctionnalités qu'ils ajoutent à ce site. Il est recommandé de laisser tous les
                cookies si vous n'êtes pas sûr d'en avoir besoin ou non au cas où ils seraient utilisés pour fournir un
                service que vous utilisez.</p><p><strong>Désactivation des cookies</strong></p><p>Vous pouvez empêcher
                l'installation de cookies en ajustant les paramètres de votre navigateur (voir l'aide de votre
                navigateur pour savoir comment procéder). Sachez que la désactivation des cookies affectera la
                fonctionnalité de ce site et de nombreux autres sites Web que vous visitez. La désactivation des cookies
                entraînera généralement la désactivation de certaines fonctionnalités et caractéristiques de ce site. Il
                est donc recommandé de ne pas désactiver les cookies.</p><p><strong>Les cookies que nous
                installons</strong></p>
            <ul>
                <li><p>Cookies de préférences du site</p><p>Afin de vous offrir une excellente expérience sur ce site,
                    nous fournissons la fonctionnalité permettant de définir vos préférences quant au fonctionnement de
                    ce site lorsque vous l'utilisez. Afin de mémoriser vos préférences, nous devons définir des cookies
                    afin que ces informations puissent être appelées chaque fois que vous interagissez avec une page
                    affectée par vos préférences.</p></li>
            </ul>
            <p><strong>Cookies tiers</strong></p><p>Dans certains cas particuliers, nous utilisons également des cookies
                fournis par des tiers de confiance. La section suivante détaille les cookies tiers que vous pourriez
                rencontrer via ce site.</p>
            <ul>
                <li><p>De temps en temps, nous testons de nouvelles fonctionnalités et apportons des modifications
                    subtiles à la manière dont le site est livré. Lorsque nous testons encore de nouvelles
                    fonctionnalités, ces cookies peuvent être utilisés pour garantir que vous recevez une expérience
                    cohérente sur le site tout en garantissant que nous comprenons quelles optimisations nos
                    utilisateurs apprécient le plus.</p></li>
                <li><p>Le service Google AdSense que nous utilisons pour diffuser de la publicité utilise un cookie
                    DoubleClick pour diffuser des annonces plus pertinentes sur le Web et limiter le nombre de fois
                    qu'une annonce donnée vous est présentée.</p><p>Pour plus d'informations sur Google AdSense,
                    consultez la FAQ officielle sur la confidentialité de Google AdSense.</p></li>
            </ul>
            <p><strong>Plus d'information</strong></p><p>J'espère que cela a clarifié les choses pour vous et, comme
                mentionné précédemment, s'il y a quelque chose dont vous n'êtes pas sûr d'avoir besoin ou non, il est
                généralement plus sûr de laisser les cookies activés au cas où ils interagissent avec l'une des
                fonctionnalités que vous utilisez sur notre site.</p><p>Cependant, si vous êtes toujours à la recherche
                de plus d'informations, vous pouvez nous contacter via l'une de nos méthodes de contact
                préférées:</p>
            <ul>
                <li>Courriel: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyFr;

import React from "react";

function CookiePolicyIt({site_name, site_host, admin_email}) {
    return (
        <div>
            <h1>Cookie Policy</h1>
            <p>Questa è la Cookie Policy di {site_name} , accessibile da {site_host}</p><p><strong>Cosa
            sono i cookie</strong></p><p>Come è pratica comune con quasi tutti i siti Web professionali, questo sito
            utilizza i cookie, che sono piccoli file che vengono scaricati sul tuo computer, per migliorare la tua
            esperienza. Questa pagina descrive quali informazioni raccolgono, come le utilizziamo e perché a volte
            abbiamo bisogno di memorizzare questi cookie. Condivideremo anche come impedire la memorizzazione di questi
            cookie, tuttavia ciò potrebbe ridurre o "interrompere" alcuni elementi delle funzionalità del sito.</p><p>
            <strong>Come utilizziamo i cookie</strong></p><p>Utilizziamo i cookie per una serie di motivi descritti di
            seguito. Sfortunatamente nella maggior parte dei casi non ci sono opzioni standard del settore per
            disabilitare i cookie senza disabilitare completamente le funzionalità e le caratteristiche che aggiungono a
            questo sito. Si consiglia di lasciare tutti i cookie se non si è sicuri di averne bisogno o meno nel caso in
            cui vengano utilizzati per fornire un servizio che si utilizza.</p><p><strong>Disabilitazione dei
            cookie</strong></p><p>Puoi impedire l'impostazione dei cookie modificando le impostazioni del tuo browser
            (consulta la guida del tuo browser per come fare). Tieni presente che la disabilitazione dei cookie influirà
            sulla funzionalità di questo e di molti altri siti Web che visiti. La disabilitazione dei cookie di solito
            comporta anche la disabilitazione di alcune funzionalità e caratteristiche di questo sito. Pertanto si
            raccomanda di non disabilitare i cookie.</p><p><strong>I biscotti che impostiamo</strong></p>
            <ul>
                <li><p>Cookie delle preferenze del sito</p><p>Per offrirti un'esperienza eccezionale su questo sito,
                    forniamo la funzionalità per impostare le tue preferenze su come funziona questo sito quando lo usi.
                    Per ricordare le tue preferenze abbiamo bisogno di impostare i cookie in modo che queste
                    informazioni possano essere richiamate ogni volta che interagisci con una pagina influenzata dalle
                    tue preferenze.</p></li>
            </ul>
            <p><strong>Cookie di terze parti</strong></p><p>In alcuni casi particolari utilizziamo anche cookie forniti
                da terze parti di fiducia. La sezione seguente descrive in dettaglio quali cookie di terze parti
                potresti incontrare attraverso questo sito.</p>
            <ul>
                <li><p>Di tanto in tanto testiamo nuove funzionalità e apportiamo sottili modifiche al modo in cui il
                    sito viene fornito. Quando stiamo ancora testando nuove funzionalità, questi cookie possono essere
                    utilizzati per garantire che tu riceva un'esperienza coerente mentre sei sul sito, assicurandoci al
                    contempo di comprendere quali ottimizzazioni i nostri utenti apprezzano di più.</p></li>
                <li><p>Il servizio Google AdSense che utilizziamo per la pubblicità utilizza un cookie DoubleClick per
                    pubblicare annunci più pertinenti sul Web e limitare il numero di volte in cui un determinato
                    annuncio ti viene mostrato.</p><p>Per ulteriori informazioni su Google AdSense, consulta le domande
                    frequenti sulla privacy di Google AdSense ufficiali.</p></li>
            </ul>
            <p><strong>Maggiori informazioni</strong></p><p>Si spera che questo ti abbia chiarito le cose e, come
                accennato in precedenza, se c'è qualcosa di cui non sei sicuro di aver bisogno o meno, di solito è più
                sicuro lasciare i cookie abilitati nel caso in cui interagisca con una delle funzionalità che utilizzi
                sul nostro sito.</p><p>Tuttavia, se stai ancora cercando ulteriori informazioni, puoi contattarci
                tramite uno dei nostri metodi di contatto preferiti:</p>
            <ul>
                <li>E-mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyIt;

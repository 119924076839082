import React from "react";

function CookiePolicyEs({site_name, site_host, admin_email}) {
    return (
        <div><h1>Política de Cookies de {site_name}</h1><p>Esta es la Política de Cookies de {site_name} , accesible
            desde {site_host}</p><p><strong>¿Qué son las cookies?</strong></p><p>Como es práctica común en
            casi todos los sitios web profesionales, este sitio utiliza cookies, que son pequeños archivos que se
            descargan en su computadora, para mejorar su experiencia. Esta página describe qué información recopilan,
            cómo la usamos y por qué a veces necesitamos almacenar estas cookies. También compartiremos cómo puede
            evitar que se almacenen estas cookies; sin embargo, esto puede degradar o "romper" ciertos elementos de la
            funcionalidad de los sitios.</p><p><strong>Cómo usamos las cookies</strong></p><p>Usamos cookies por una
            variedad de razones que se detallan a continuación. Desafortunadamente, en la mayoría de los casos, no
            existen opciones estándar de la industria para deshabilitar las cookies sin deshabilitar por completo la
            funcionalidad y las características que agregan a este sitio. Se recomienda que deje activadas todas las
            cookies si no está seguro de si las necesita o no en caso de que se utilicen para proporcionar un servicio
            que utiliza.</p><p><strong>Deshabilitar cookies</strong></p><p>Puede evitar la configuración de cookies
            ajustando la configuración de su navegador (consulte la Ayuda de su navegador para saber cómo hacerlo).
            Tenga en cuenta que deshabilitar las cookies afectará la funcionalidad de este y muchos otros sitios web que
            visite. La desactivación de las cookies generalmente resultará en la desactivación también de ciertas
            funciones y características de este sitio. Por lo tanto, se recomienda que no deshabilite las cookies.</p>
            <p><strong>Las cookies que configuramos</strong></p>
            <ul>
                <li><p>Cookies de preferencias del sitio</p><p>Con el fin de brindarle una gran experiencia en este
                    sitio, proporcionamos la funcionalidad para configurar sus preferencias sobre cómo se ejecuta este
                    sitio cuando lo usa. Para recordar sus preferencias, necesitamos configurar cookies para que esta
                    información pueda ser llamada cada vez que interactúe con una página que se vea afectada por sus
                    preferencias.</p></li>
            </ul>
            <p><strong>Cookies de terceros</strong></p><p>En algunos casos especiales, también utilizamos cookies
                proporcionadas por terceros de confianza. La siguiente sección detalla qué cookies de terceros puede
                encontrar a través de este sitio.</p>
            <ul>
                <li><p>De vez en cuando probamos nuevas funciones y hacemos cambios sutiles en la forma en que se
                    entrega el sitio. Cuando todavía estamos probando nuevas funciones, estas cookies pueden usarse para
                    garantizar que reciba una experiencia consistente mientras está en el sitio y, al mismo tiempo,
                    asegurarnos de que entendemos qué optimizaciones aprecian más nuestros usuarios.</p></li>
                <li><p>El servicio de Google AdSense que utilizamos para publicar publicidad utiliza una cookie de
                    DoubleClick para publicar anuncios más relevantes en la web y limitar la cantidad de veces que se le
                    muestra un anuncio determinado.</p><p>Para obtener más información sobre Google AdSense, consulte
                    las preguntas frecuentes oficiales sobre privacidad de Google AdSense.</p></li>
            </ul>
            <p><strong>Más información</strong></p><p>Con suerte, eso le ha aclarado las cosas y, como se mencionó
                anteriormente, si hay algo que no está seguro de si necesita o no, generalmente es más seguro dejar las
                cookies habilitadas en caso de que interactúe con una de las funciones que usa en nuestro sitio.</p>
            <p>Sin embargo, si todavía está buscando más información, puede contactarnos a través de uno de nuestros
                métodos de contacto preferidos:</p>
            <ul>
                <li>Correo electrónico: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyEs;

import React from "react";

function CookiePolicyPl({site_name, site_host, admin_email}) {
    return (
        <div><h1>Polityka plików cookie dla {site_name}</h1><p>To jest polityka dotycząca plików cookie dla {site_name}
            , dostępna pod adresem {site_host}</p><p><strong>Czym są pliki cookie</strong></p><p>Zgodnie z
            powszechną praktyką w przypadku prawie wszystkich profesjonalnych witryn internetowych, ta witryna używa
            plików cookie, które są małymi plikami, które są pobierane na komputer, aby poprawić Twoje wrażenia. Ta
            strona opisuje, jakie informacje zbierają, w jaki sposób je wykorzystujemy i dlaczego czasami musimy
            przechowywać te pliki cookie. Poinformujemy również, w jaki sposób można zapobiec przechowywaniu tych plików
            cookie, jednak może to obniżyć lub „zakłócić” niektóre elementy funkcjonalności witryn.</p><p><strong>Jak
            używamy plików cookie</strong></p><p>Używamy plików cookie z różnych powodów wyszczególnionych poniżej.
            Niestety w większości przypadków nie ma standardowych opcji branżowych umożliwiających wyłączenie plików
            cookie bez całkowitego wyłączenia funkcjonalności i funkcji, które dodają do tej witryny. Zaleca się
            pozostawienie wszystkich plików cookie, jeśli nie masz pewności, czy ich potrzebujesz, czy nie, jeśli są one
            wykorzystywane do świadczenia usług, z których korzystasz.</p><p><strong>Wyłączanie plików cookie</strong>
        </p><p>Możesz zapobiec ustawianiu plików cookie, dostosowując ustawienia w swojej przeglądarce (patrz Pomoc
            przeglądarki, jak to zrobić). Należy pamiętać, że wyłączenie plików cookie wpłynie na funkcjonalność tej i
            wielu innych odwiedzanych witryn. Wyłączenie plików cookie zwykle powoduje również wyłączenie niektórych
            funkcjonalności i funkcji tej witryny. Dlatego zaleca się, aby nie wyłączać plików cookie.</p><p><strong>Pliki
            cookie, które ustawiamy</strong></p>
            <ul>
                <li><p>Pliki cookie preferencji witryny</p><p>Aby zapewnić Ci jak najlepsze wrażenia z korzystania z tej
                    witryny, udostępniamy funkcję umożliwiającą ustawienie preferencji dotyczących sposobu działania tej
                    witryny podczas korzystania z niej. Aby zapamiętać Twoje preferencje, musimy ustawić pliki cookie,
                    aby te informacje mogły być wywoływane za każdym razem, gdy wchodzisz w interakcję ze stroną, na
                    którą wpływają Twoje preferencje.</p></li>
            </ul>
            <p><strong>Pliki cookie stron trzecich</strong></p><p>W niektórych szczególnych przypadkach używamy również
                plików cookie dostarczanych przez zaufane strony trzecie. Poniższa sekcja zawiera szczegółowe informacje
                na temat plików cookie stron trzecich, które możesz napotkać za pośrednictwem tej witryny.</p>
            <ul>
                <li><p>Od czasu do czasu testujemy nowe funkcje i wprowadzamy subtelne zmiany w sposobie dostarczania
                    witryny. Gdy wciąż testujemy nowe funkcje, te pliki cookie mogą być używane w celu zapewnienia
                    spójnego korzystania z witryny, jednocześnie zapewniając, że rozumiemy, które optymalizacje nasi
                    użytkownicy najbardziej cenią.</p></li>
                <li><p>Usługa Google AdSense, której używamy do wyświetlania reklam, wykorzystuje plik cookie
                    DoubleClick, aby wyświetlać trafniejsze reklamy w sieci i ograniczać liczbę wyświetleń danej
                    reklamy.</p><p>Więcej informacji na temat Google AdSense można znaleźć w oficjalnych Najczęściej
                    zadawanych pytaniach dotyczących prywatności w Google AdSense.</p></li>
            </ul>
            <p><strong>Więcej informacji</strong></p><p>Mamy nadzieję, że to wyjaśniło ci sprawę i jak wspomniano
                wcześniej, jeśli jest coś, czego nie masz pewności, czy potrzebujesz, czy nie, zwykle bezpieczniej jest
                pozostawić włączone pliki cookie, na wypadek interakcji z jedną z funkcji, z których korzystasz na
                naszej stronie.</p><p>Jeśli jednak nadal szukasz więcej informacji, możesz skontaktować się z nami za
                pośrednictwem jednej z naszych preferowanych metod kontaktu:</p>
            <ul>
                <li>E-mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyPl;
